import { useState } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
const CustomButton = ({
  to = null,
  onClick = null,
  children,
  style = {},
  disabled = false,
  type = "main",
}) => {
  const [pressed, setPressed] = useState(false);

  let buttonClass = "custom-button";
  if (type === "upgrade") buttonClass = "custom-button-upgrade";
  return (
    <Link
      to={disabled ? null : to}
      onClick={disabled ? null : onClick}
      onTouchStart={() => {
        setPressed(true);
      }}
      onTouchEnd={() => {
        setPressed(false);
      }}
      className={`${buttonClass} ${pressed ? "pressed" : ""} ${
        disabled ? "disabled" : ""
      }`}
      style={style}
    >
      {children}
    </Link>
  );
};

export default CustomButton;
