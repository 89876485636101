import { useCallback, useEffect, useState } from "react";
import useStepCounter from "../../components/StepCounter";
import { UpgradeContainerPage } from "../../components/UpgradeContainer";
import { getUpgrades } from "../../utils/API/upgrades";
import { useSteps } from "../../utils/StepContext";
import "./styles.css";
import BackButton from "../../components/BackButton";

const UpgradesPage = () => {
  const { isStepInitialized, handleIncreaseSteps, currency } = useSteps();
  useStepCounter(isStepInitialized, handleIncreaseSteps);

  // state

  const [upgrades, setUpgrades] = useState(null);

  // api calls

  const fetchUpgrades = useCallback(async () => {
    const response = await getUpgrades();

    if (response.code === 200) {
      setUpgrades(response.data);
      return;
    }

    console.log(response);
  }, []);

  useEffect(() => {
    fetchUpgrades();
  }, [fetchUpgrades]);

  return (
    <>
      <img
        className="background"
        src="assets/upgrades-background.webp"
        alt=""
      />
      <BackButton style={{ top: "10px", left: "10px" }} />
      <div className="fixed upgrades-animation-top"></div>
      <div className="fixed upgrades-animation-bottom"></div>

      <UpgradeContainerPage
        upgrades={upgrades}
        currency={currency}
        onUpgradeBuy={async () => {
          await fetchUpgrades();
        }}
      />
    </>
  );
};

export default UpgradesPage;
