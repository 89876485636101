import { useState } from "react";
import "./styles.css";
const CustomInput = ({ value, onInput, type = "text", placeholder = "" }) => {
  const [focus, setFocus] = useState(false);

  return (
    <input
      className={`custom-input ${focus ? "focus" : ""}`}
      type={type}
      value={value}
      onInput={(e) => onInput(e.target.value)}
      placeholder={placeholder}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    />
  );
};

export default CustomInput;
