import CustomButton from "../../components/CustomButton";
import { LogOut } from "../../utils/API/auth";
import "./styles.css";

const LogOutPage = () => {
  const handleLogOut = () => {
    LogOut();

    window.location.refresh();

    return;
  };
  return (
    <>
      <div className="fixed logout-image">
        <img src="assets/logout-image.webp" alt="" />
      </div>

      <div className="fixed logout-button">
        <CustomButton onClick={handleLogOut} style={{ height: "100%" }}>
          Log Out
        </CustomButton>
      </div>
    </>
  );
};

export default LogOutPage;
