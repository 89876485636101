import { createContext, useContext, useState, useEffect, useRef } from "react";
import { initializeApplication } from "./API/init";
import { getCookie } from "./cookie";
import { updateSteps } from "./API/steps";
import { buyUpgrade } from "./API/upgrades";

const StepsContext = createContext();

export const useSteps = () => {
  const context = useContext(StepsContext);
  if (!context) {
    throw new Error("useSteps must be used within a StepsProvider");
  }
  return context;
};

export const StepsProvider = ({ children }) => {
  const [isStepInitialized, setIsStepInitialized] = useState(false);

  const [currency, setCurrency] = useState(null);
  const [steps, setSteps] = useState(null);
  const stepsRef = useRef(null);
  const currencyIncreaseFactor = useRef(null);
  const priceReductionFactor = useRef(null);

  const [isAuthenticated, setIsAuthenticated] = useState(
    getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE) !== null
  );
  const [forceUpdate, setForceUpdate] = useState(0);

  const intervalRef = useRef(null);

  // this function will be called when a step is performed

  const handleIncreaseSteps = () => {
    console.log("[sc] increase factor ", currencyIncreaseFactor.current);

    setSteps((steps) => steps + 1);
    stepsRef.current += 1;

    setCurrency(
      (currency) =>
        Math.round((currency + currencyIncreaseFactor.current) * 100) / 100
    );
  };

  const StepForceUpdate = () => {
    console.log("[sc] force update");
    setForceUpdate((forceUpdate) => forceUpdate + 1);
  };

  // When the app starts, fetch:
  // currency increase factor, price reduction factor
  // steps and currency
  // from the database and save them into the context

  const initializeProvider = async () => {
    if (!isAuthenticated) {
      return;
    }

    const response = await initializeApplication();
    if (response.code === 200) {
      setCurrency(response.data.currency);
      currencyIncreaseFactor.current = response.data.currency_increase_factor;
      priceReductionFactor.current = response.data.price_reduction_factor;
      setSteps(response.data.total_steps);
      stepsRef.current = response.data.total_steps;
      setIsStepInitialized(true);
      return;
    }

    alert("Error loading data");
  };

  useEffect(() => {
    (async () => {
      await initializeProvider();
    })();
  }, [isAuthenticated, forceUpdate]);

  const UpdateProvider = async () => {
    if (!isAuthenticated) {
      return;
    }
    const response = await updateSteps(stepsRef.current);

    if (response.code === 200) {
      await initializeProvider();
      return;
    } else {
      alert("Error updating steps");
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (intervalRef.current) clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      (async () => {
        await UpdateProvider();
      })();
    }, 15000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isAuthenticated]);

  const onBuyUpgrade = async (upgradeId) => {
    await UpdateProvider();

    const response = await buyUpgrade(upgradeId);
    if (response.code === 200) {
      await UpdateProvider();
    } else {
      alert("You cannot buy that!");
    }
  };
  return (
    <StepsContext.Provider
      value={{
        steps,
        setSteps,
        currency,
        setCurrency,
        currencyIncreaseFactor,
        priceReductionFactor,
        isAuthenticated,
        setIsAuthenticated,
        isStepInitialized,
        setIsStepInitialized,
        handleIncreaseSteps,
        StepForceUpdate,
        UpdateProvider,
        onBuyUpgrade,
      }}
    >
      {children}
    </StepsContext.Provider>
  );
};
