import { useEffect, useRef } from "react";
import "./styles.css";

const ScrollContent = ({
  className = "",
  scrollToBottom = false,
  children,
}) => {
  const divRef = useRef(null);

  useEffect(() => {
    if (scrollToBottom) {
      /* WIP */
    }
  }, [children, scrollToBottom]);

  return (
    <div ref={divRef} className={`absolute scroll-content ${className}`}>
      {children}
    </div>
  );
};

export default ScrollContent;
