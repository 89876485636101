import React, { useEffect, useState } from "react";
import axios from "axios";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import HomePage from "./screens/HomePage";
import ProtectedRoute from "./utils/ProtectedRoute";
import AuthenticatePage from "./screens/AuthenticatePage";
import RegisterPage from "./screens/RegisterPage";
import WelcomePage from "./screens/WelcomePage";
import "./App.css";
import LogOutPage from "./screens/LogOutPage";
import InstallPage from "./screens/InstallPage";
import useStepCounter from "./components/StepCounter";
import { getCookie } from "./utils/cookie";
import { StepsProvider } from "./utils/StepContext";
import StatsPage from "./screens/StatsPage";
import UpgradesPage from "./screens/UpgradesPage";
import SettingsPage from "./screens/SettingsPage";

axios.defaults.withCredentials = true;

function App() {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  /*const fetchBackend = async () => {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/register",
      {
        name: "r  obertel",
        email: "robert.takacs.5033@gmail.com",
        password: "123456",
        password_confirmation: "123456",
      }
    );
    console.log(response);
  };*/

  useEffect(() => {
    if (!loaded || error) {
      return;
    }
  }, []);

  useEffect(() => {
    axios
      .get("/sanctum/csrf-cookie")
      .then((response) => {})
      .catch((e) => {
        setError(true);
      })
      .finally(() => {
        setLoaded(true);
      });
  }, []);

  if (!loaded)
    return (
      <>
        <h1>Loading</h1>
      </>
    );

  if (error)
    return (
      <>
        <h1>Error</h1>
      </>
    );

  return (
    <StepsProvider>
      <Router>
        <Routes>
          <Route
            path="/go-mobile"
            element={
              <ProtectedRoute protection="allow_pc prevent_phone allow_browser" />
            }
          >
            <Route index element={<InstallPage />} />
          </Route>

          <Route
            path="/go-install"
            element={
              <ProtectedRoute protection="allow_browser prevent_app allow_pc" />
            }
          >
            <Route index element={<InstallPage />} />
          </Route>

          <Route path="/" element={<ProtectedRoute protection="login" />}>
            <Route index element={<HomePage />} />
          </Route>

          <Route path="/logout" element={<ProtectedRoute protection="login" />}>
            <Route index element={<LogOutPage />} />
          </Route>

          <Route path="/stats" element={<ProtectedRoute protection="login" />}>
            <Route index element={<StatsPage />} />
          </Route>

          <Route
            path="/upgrades"
            element={<ProtectedRoute protection="login" />}
          >
            <Route index element={<UpgradesPage />} />
          </Route>

          <Route
            path="/settings"
            element={<ProtectedRoute protection="login" />}
          >
            <Route index element={<SettingsPage />} />
          </Route>

          <Route
            path="/welcome"
            element={<ProtectedRoute protection="guest" />}
          >
            <Route index element={<WelcomePage />} />
          </Route>

          <Route
            path="/authenticate"
            element={<ProtectedRoute protection="guest" />}
          >
            <Route index element={<AuthenticatePage />} />
          </Route>

          <Route
            path="/register"
            element={<ProtectedRoute protection="guest" />}
          >
            <Route index element={<RegisterPage />} />
          </Route>
        </Routes>
      </Router>
    </StepsProvider>
  );
}

export default App;
