import { useCallback, useEffect, useState } from "react";
import BackButton from "../../components/BackButton";
import ScrollContent from "../../components/ScrollContent";
import "./styles.css";
import { getUserStatistics } from "../../utils/API/init";
import { useSteps } from "../../utils/StepContext";
import useStepCounter from "../../components/StepCounter";

const StatsPage = () => {
  const [stats, setStats] = useState({});

  // custom hooks
  const { isStepInitialized, steps, handleIncreaseSteps } = useSteps();
  useStepCounter(isStepInitialized, handleIncreaseSteps);

  const getUserStats = useCallback(async () => {
    const statsResponse = await getUserStatistics();

    if (statsResponse.code === 200) {
      setStats(statsResponse.data);
    } else {
      console.log("error");
    }
  }, []);

  useEffect(() => {
    getUserStats();
  }, [getUserStats]);

  useEffect(() => {
    setStats((prev) => ({
      ...prev,
      steps_today: prev.steps_today + 1,
    }));
  }, [steps]);

  return (
    <>
      <img className="background" src="assets/stats-background.webp" alt="" />
      <BackButton style={{ top: "10px", left: "10px" }} />

      <ScrollContent className="fixed stats-wrapper">
        <h2>Steps</h2>
        <p>Total steps: {steps}</p>
        <p>Steps taken today: {stats.steps_today ?? "Loading"}</p>

        <h2>Currency</h2>
        <p>Total currency gained: ${stats.currency_total ?? "Loading"}</p>

        <h2>Upgrades</h2>
        <p>Upgrades owned: {stats.number_of_upgrades ?? "Loading"}</p>
        <p>
          Currency spent on upgrades: $
          {stats.currency_spent_on_upgrades ?? "Loading"}
        </p>

        <h2>History</h2>
        <p>Joined on: {stats.joined_on ?? "Loading"}</p>
      </ScrollContent>
    </>
  );
};

export default StatsPage;
