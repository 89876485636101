import "./styles.css";

const PageTitle = ({
  dimension = "small",
  float = "center",
  children,
  subtitle = null,
  subtitle_class = "",
  glow = null,
}) => {
  return (
    <div
      className={`fixed no-bg page-title-wrapper ${dimension} float-${float}`}
      style={{
        textShadow: glow ? `0 0 5px ${glow}` : null,
      }}
    >
      <h1>{children}</h1>

      {subtitle && (
        <p className={`page-title-subtitle ${subtitle_class}`}>{subtitle}</p>
      )}
    </div>
  );
};

const LoadingTitle = () => {
  return <PageTitle dimension="small-middle">Loading...</PageTitle>;
};

export default PageTitle;
export { LoadingTitle };
