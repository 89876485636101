import { useSteps } from "../../utils/StepContext";
import CustomButton from "../CustomButton";
import ScrollContent from "../ScrollContent";

import "./styles.css";
const UpgradeContainerHome = ({ upgrades, currency, onUpgradeBuy }) => {
  const { onBuyUpgrade } = useSteps();

  if (!upgrades) {
    return (
      <div className="fixed upgrade-container-home">
        <h2>Upgrades</h2>
        <ScrollContent>
          <div></div>
          <div></div>
          <div></div>
        </ScrollContent>
      </div>
    );
  }

  return (
    <div className="fixed upgrade-container-home">
      <h2>Upgrades</h2>
      <ScrollContent>
        {upgrades.map((upgrade) => (
          <CustomButton
            key={upgrade.id}
            onClick={async () => {
              upgrade.awaitingBuy = true;
              await onBuyUpgrade(upgrade.id);
              await onUpgradeBuy();
              upgrade.awaitingBuy = false;
            }}
            type="upgrade"
            disabled={currency < upgrade.next_price || upgrade.awaitingBuy}
          >
            {upgrade.name} ${upgrade.next_price} (x{upgrade.quantity})
          </CustomButton>
        ))}
      </ScrollContent>
    </div>
  );
};

const UpgradeContainerPage = ({ upgrades, currency, onUpgradeBuy }) => {
  const { onBuyUpgrade } = useSteps();

  if (!upgrades) {
    return (
      <div className="fixed upgrade-container-page">
        <h2>Upgrades</h2>
        <ScrollContent>
          <div></div>
          <div></div>
          <div></div>
        </ScrollContent>
      </div>
    );
  }

  return (
    <div className="fixed upgrade-container-page">
      <h2>Upgrades</h2>
      <ScrollContent>
        {upgrades.map((upgrade) => (
          <div key={upgrade.id}>
            <CustomButton
              key={upgrade.id}
              onClick={async () => {
                upgrade.awaitingBuy = true;
                await onBuyUpgrade(upgrade.id);
                await onUpgradeBuy();
                upgrade.awaitingBuy = false;
              }}
              type="upgrade"
              disabled={currency < upgrade.next_price || upgrade.awaitingBuy}
            >
              {upgrade.name} ${upgrade.next_price}
            </CustomButton>
            <p className="quantity">
              {upgrade.quantity
                ? "Bought " +
                  upgrade.quantity +
                  " time" +
                  (upgrade.quantity > 1 ? "s" : "")
                : "Not owned yet"}
            </p>
          </div>
        ))}
      </ScrollContent>
    </div>
  );
};

export { UpgradeContainerHome, UpgradeContainerPage };
