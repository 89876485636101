import axios from "axios";
import { getCookie } from "../cookie";

// we will save

const getUpgrades = async () => {
  /*if (localStorage.getItem("upgrades")) {
    return JSON.parse(localStorage.getItem("upgrades"));
  }*/

  const upgradesResponse = await axios.get(
    process.env.REACT_APP_API_URL + "/upgrades/store",
    {
      headers: {
        Authorization: `Bearer ${getCookie(
          process.env.REACT_APP_COOKIE_OBFUSCATE
        )}`,
      },
    }
  );

  if (upgradesResponse.status === 200) {
    const data = upgradesResponse.data.map((upgrade) => {
      return {
        ...upgrade,
        awaitingBuy: false,
      };
    });

    const response = {
      code: upgradesResponse.status,
      data: data,
    };

    localStorage.setItem("upgrades", JSON.stringify(response));

    return response;
  }

  return {
    code: upgradesResponse.status,
  };
};

const buyUpgrade = async (upgradeId) => {
  const buyResponse = await axios.post(
    process.env.REACT_APP_API_URL + "/user/buy-upgrade/" + upgradeId,
    {},
    {
      headers: {
        Authorization: `Bearer ${getCookie(
          process.env.REACT_APP_COOKIE_OBFUSCATE
        )}`,
      },
    }
  );

  if (buyResponse.status === 200) {
    return {
      code: 200,
      data: buyResponse.data,
    };
  }
  return {
    code: buyResponse.status,
  };
};

export { getUpgrades, buyUpgrade };
