import { useEffect, useRef } from "react";

const useStepCounter = (isStepInitialized, handleIncreaseSteps) => {
  const accRef = useRef(null);

  const incrementStepsOnClick = () => {
    if (isStepInitialized === false) return;
    handleIncreaseSteps();
  };

  useEffect(() => {
    if (isStepInitialized === false) return;

    if (process.env.REACT_APP_INCREMENT_CLICK == "true")
      window.addEventListener("click", incrementStepsOnClick);

    if ("Accelerometer" in window) {
      let lastVal = 0;
      let lastPeakTime = 0;
      let lastTroughTime = 0;
      let peak = false;

      const alpha = 0.1;
      let gravityX = 0;
      let gravityY = 0;
      let gravityZ = 0;

      const sensitivityThreshold = 0.75;
      const timeThreshold = 400;
      const varianceThreshold = 0.1;

      const bufferSize = 10;
      const buffer = Array(bufferSize).fill(0);
      let bufferIndex = 0;

      const accelerometer = new window.Accelerometer({ frequency: 60 });
      accRef.current = accelerometer;

      accelerometer.addEventListener("reading", () => {
        const currentTime = Date.now();

        gravityX = accelerometer.x * alpha + gravityX * (1.0 - alpha);
        gravityY = accelerometer.y * alpha + gravityY * (1.0 - alpha);
        gravityZ = accelerometer.z * alpha + gravityZ * (1.0 - alpha);

        const userAccX = accelerometer.x - gravityX;
        const userAccY = accelerometer.y - gravityY;
        const userAccZ = accelerometer.z - gravityZ;

        const magnitude = Math.sqrt(
          userAccX * userAccX + userAccY * userAccY + userAccZ * userAccZ
        );

        buffer[bufferIndex] = magnitude;
        bufferIndex = (bufferIndex + 1) % bufferSize;

        const mean = buffer.reduce((acc, val) => acc + val, 0) / bufferSize;
        const variance =
          buffer.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) /
          bufferSize;

        if (variance < varianceThreshold) {
          return;
        }

        if (magnitude > lastVal) {
          if (!peak && currentTime - lastTroughTime > timeThreshold) {
            peak = true;
            lastPeakTime = currentTime;
          }
        } else {
          if (peak && currentTime - lastPeakTime > timeThreshold) {
            peak = false;
            handleIncreaseSteps();
            lastTroughTime = currentTime;
          }
        }

        lastVal = magnitude;
      });

      accelerometer.start();
    }

    return () => {
      accRef.current.stop();
      if (process.env.REACT_APP_INCREMENT_CLICK == "true")
        window.removeEventListener("click", incrementStepsOnClick);
    };
  }, [isStepInitialized]);

  return true;
};

export default useStepCounter;
