// ProtectedRoute.js

import { Navigate, Outlet } from "react-router-dom";
import { isLoggedIn } from "./API/auth.js";
import { useSteps } from "./StepContext.js";
import { useEffect } from "react";

const ProtectedRoute = ({ protection }) => {
  let protections = protection.split(" ");

  if (process.env.REACT_APP_ALLOW_BROWSER !== "true") {
    if (!protections.find((el) => el === "allow_browser")) {
      if (
        !(
          window.matchMedia("(display-mode: standalone)").matches ||
          window.navigator.standalone ||
          document.referrer.includes("android-app://")
        )
      )
        return <Navigate to="/go-install" replace />;
    }

    if (protections.find((el) => el === "prevent_app")) {
      if (
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator.standalone ||
        document.referrer.includes("android-app://")
      )
        return <Navigate to="/" replace />;
    }
  }

  if (!protections.find((el) => el === "allow_pc")) {
    if (!/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
      return <Navigate to="/go-mobile" replace />;
  }

  if (protections.find((el) => el === "prevent_phone")) {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
      return <Navigate to="/" replace />;
  }

  if (protections.find((el) => el === "login")) {
    if (!isLoggedIn()) return <Navigate to="/welcome" replace />;
  }

  if (protections.find((el) => el === "guest")) {
    if (isLoggedIn()) return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
