import { Link } from "react-router-dom";
import "./styles.css";

const BackButton = ({ style, to = "/" }) => {
  return (
    <div className="fixed back-button-wrapper" style={style}>
      <Link to={to}>
        <img className="fluid" src="assets/back.png" alt="" />
      </Link>
    </div>
  );
};

export default BackButton;
