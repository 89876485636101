import useStepCounter from "../../components/StepCounter";
import { useSteps } from "../../utils/StepContext";
import "./styles.css";
import BackButton from "../../components/BackButton";
import ScrollContent from "../../components/ScrollContent";
import { Link } from "react-router-dom";

const SettingsPage = () => {
  const { isStepInitialized, handleIncreaseSteps } = useSteps();
  useStepCounter(isStepInitialized, handleIncreaseSteps);

  return (
    <>
      <img
        className="background"
        src="assets/settings-background.webp"
        alt=""
      />
      <BackButton style={{ top: "10px", left: "10px" }} />

      <ScrollContent className="fixed settings-page-wrapper">
        <Link to="/logout">Log Out</Link>
      </ScrollContent>
    </>
  );
};

export default SettingsPage;
