import PageTitle from "../../components/PageTitle";
import "./styles.css";
import CustomButton from "../../components/CustomButton";
const WelcomePage = () => {
  return (
    <>
      <PageTitle dimension="big-top">Step Up!</PageTitle>

      <div className="fixed welcome-image">
        <img src="assets/welcome.webp" alt="" />
      </div>
      <div className="fixed welcome-buttons">
        <CustomButton to="/authenticate">Login</CustomButton>
        <CustomButton to="/register"> Register </CustomButton>
      </div>
      <div className="fixed accent">Terms & Conditions</div>
    </>
  );
};

export default WelcomePage;
