import PageTitle from "../../components/PageTitle";
const InstallPage = () => {
  return (
    <>
      <div className="img-background">
        <img src="/assets/install.webp" alt="" />
      </div>
      <PageTitle dimension="big-top" glow={"#FFFFFF "}>
        Please Install the App
      </PageTitle>
    </>
  );
};

export default InstallPage;
