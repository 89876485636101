import { useCallback, useEffect, useState } from "react";
import useStepCounter from "../../components/StepCounter";
import { useSteps } from "../../utils/StepContext";

import "./styles.css";
import { getUpgrades } from "../../utils/API/upgrades";
import { StepShowcaseHome } from "../../components/StepShowcase";
import { CurrencyShowcaseHome } from "../../components/CurrencyShowcase";
import { UpgradeContainerHome } from "../../components/UpgradeContainer";
import { MenuContainerHome } from "../../components/MenuContainer";
const HomePage = () => {
  // custom hooks
  const {
    isStepInitialized,
    steps,
    handleIncreaseSteps,
    currency,
    UpdateProvider,
  } = useSteps();
  useStepCounter(isStepInitialized, handleIncreaseSteps);

  // state

  const [upgrades, setUpgrades] = useState(null);

  // api calls

  const fetchUpgrades = useCallback(async () => {
    const response = await getUpgrades();

    if (response.code === 200) {
      setUpgrades(response.data);
      return;
    }

    console.log(response);
  }, []);

  useEffect(() => {
    fetchUpgrades();
  }, [fetchUpgrades]);

  // jsx creation

  return (
    <>
      <img className="background" src="assets/main-background.webp" alt="" />

      <StepShowcaseHome initialized={isStepInitialized} steps={steps} />
      <CurrencyShowcaseHome
        initialized={isStepInitialized}
        currency={currency}
      />
      <UpgradeContainerHome
        upgrades={upgrades}
        currency={currency}
        onUpgradeBuy={async () => {
          await fetchUpgrades();
        }}
      />

      <MenuContainerHome handleItemClick={UpdateProvider} />
    </>
  );
};

export default HomePage;
