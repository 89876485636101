import { Link, useNavigate } from "react-router-dom";
import ScrollContent from "../ScrollContent";
import "./styles.css";
import { useSteps } from "../../utils/StepContext";

const MenuContainerHome = ({ handleItemClick }) => {
  const navigator = useNavigate();

  const handleClick = (to) => {
    handleItemClick();

    navigator(to);
  };
  return (
    <ScrollContent className="fixed menu-container-home">
      <div>
        <div
          onClick={() => {
            handleClick("/stats");
          }}
        >
          <img className="fluid" src="/assets/icon/stats.png" />
        </div>
      </div>
      <div>
        <div
          onClick={() => {
            handleClick("/upgrades");
          }}
        >
          <img className="fluid" src="/assets/icon/upgrades.png" />
        </div>
      </div>

      <div>
        <div
          onClick={() => {
            handleClick("/settings");
          }}
        >
          <img className="fluid" src="/assets/icon/settings.png" />
        </div>
      </div>
    </ScrollContent>
  );
};

export { MenuContainerHome };
