import "./styles.css";

const StepShowcaseHome = ({ initialized, steps = null }) => {
  if (!initialized) {
    return (
      <div className="fixed step-showcase-home loading">
        <div></div>
      </div>
    );
  }

  return (
    <div className="fixed step-showcase-home">
      <div>
        {steps} Step{steps > 1 ? "s" : ""}
      </div>
    </div>
  );
};

export { StepShowcaseHome };
