import { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import PageTitle from "../../components/PageTitle";

import "./styles.css";
import { handleRequestLogin } from "../../utils/API/auth";
import { useNavigate } from "react-router-dom";
import { useSteps } from "../../utils/StepContext";
import BackButton from "../../components/BackButton";

const AuthenticatePage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loginDisabled, setLoginDisabled] = useState(true);

  const navigator = useNavigate();

  const { setIsAuthenticated } = useSteps();

  useEffect(() => {
    if (!username.trim() || !password.trim()) {
      setLoginDisabled(true);
    } else {
      setLoginDisabled(false);
    }
  }, [username, password]);

  const handleLogin = async () => {
    let loginResponse = await handleRequestLogin({
      username,
      password,
    });

    if (loginResponse !== 0) {
      alert(loginResponse.message);
      return;
    }
    setIsAuthenticated(true);
    navigator("/");
  };
  return (
    <>
      <img className="background" src="assets/login-background.webp" alt="" />
      <PageTitle dimension="big-top" glow={"#FFFFFF "}>
        Let's go!
      </PageTitle>
      <BackButton to="/welcome" />

      <div className="fixed login-form">
        <CustomInput
          value={username}
          onInput={setUsername}
          placeholder={"Username"}
        />
        <CustomInput
          type="password"
          value={password}
          onInput={setPassword}
          placeholder={"Password"}
        />
      </div>

      <div className="fixed login-button">
        <CustomButton
          onClick={handleLogin}
          style={{ height: "100%" }}
          disabled={loginDisabled}
        >
          Login
        </CustomButton>
      </div>
    </>
  );
};

export default AuthenticatePage;
