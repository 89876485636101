import "./styles.css";

const CurrencyShowcaseHome = ({ initialized, currency = null }) => {
  if (!initialized) {
    return (
      <div className="fixed currency-showcase-home loading">
        <div></div>
      </div>
    );
  }

  return (
    <div className="fixed currency-showcase-home">
      <div>${currency}</div>
    </div>
  );
};

export { CurrencyShowcaseHome };
