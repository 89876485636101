import axios from "axios";
import { getCookie } from "../cookie";

const updateSteps = async (steps) => {
  const updateResponse = await axios.post(
    process.env.REACT_APP_API_URL + "/user/steps",
    {
      total_steps: steps,
    },
    {
      headers: {
        Authorization: `Bearer ${getCookie(
          process.env.REACT_APP_COOKIE_OBFUSCATE
        )}`,
      },
    }
  );

  if (updateResponse.status === 200) {
    return {
      code: 200,
      data: updateResponse.data,
    };
  }
};

export { updateSteps };
