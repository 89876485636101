import axios from "axios";
import { getCookie } from "../cookie";

// we will save

const initializeApplication = async () => {
  const initializeResponse = await axios.get(
    process.env.REACT_APP_API_URL + "/user/initialize",
    {
      headers: {
        Authorization: `Bearer ${getCookie(
          process.env.REACT_APP_COOKIE_OBFUSCATE
        )}`,
      },
    }
  );

  if (initializeResponse.status === 200) {
    //console.log("[sc] initialize response", initializeResponse.data);
    const data = initializeResponse.data;

    return {
      code: 200,
      data: data,
    };
  }

  return {
    code: initializeResponse.status,
  };
};

const getUserStatistics = async () => {
  const statsResponse = await axios.get(
    process.env.REACT_APP_API_URL + "/user/stats",
    {
      headers: {
        Authorization: `Bearer ${getCookie(
          process.env.REACT_APP_COOKIE_OBFUSCATE
        )}`,
      },
    }
  );

  if (statsResponse.status === 200) {
    return {
      code: 200,
      data: statsResponse.data,
    };
  }

  return {
    code: statsResponse.status,
  };
};

export { initializeApplication, getUserStatistics };
