import { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import PageTitle from "../../components/PageTitle";
import "./styles.css";
import { handleRequestRegister } from "../../utils/API/auth";
import { useNavigate } from "react-router-dom";
import { useSteps } from "../../utils/StepContext";
import BackButton from "../../components/BackButton";

const RegisterPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [registerDisabled, setRegisterDisabled] = useState(true);

  const { setIsAuthenticated } = useSteps();

  const navigator = useNavigate();
  useEffect(() => {
    if (
      !username.trim() ||
      !password.trim() ||
      !passwordConfirmation.trim() ||
      !email.trim()
    ) {
      setRegisterDisabled(true);
    } else if (
      username.trim().length < 3 ||
      password.trim().length < 5 ||
      passwordConfirmation.trim().length < 5 ||
      email.trim().length < 5
    ) {
      setRegisterDisabled(true);
    } else if (password !== passwordConfirmation) {
      setRegisterDisabled(true);
    } else {
      setRegisterDisabled(false);
    }
  }, [username, password, passwordConfirmation, email]);
  const handleRegister = async () => {
    let registerResponse = await handleRequestRegister({
      username,
      email,
      password,
      passwordConfirmation,
    });

    if (registerResponse !== 0) {
      alert(registerResponse.message);
      return;
    }

    setIsAuthenticated(true);
    navigator("/");
  };
  return (
    <>
      <img
        className="background"
        src="assets/register-background.webp"
        alt=""
      />
      <PageTitle dimension="big-top" glow={"#FFFFFF "}>
        Welcome!
      </PageTitle>

      <BackButton to="/welcome" />

      <div className="fixed register-form">
        <CustomInput
          value={username}
          onInput={setUsername}
          placeholder={"Username"}
        />
        <CustomInput value={email} onInput={setEmail} placeholder={"E-mail"} />
        <CustomInput
          type="password"
          value={password}
          onInput={setPassword}
          placeholder={"Password"}
        />
        <CustomInput
          type="password"
          value={passwordConfirmation}
          onInput={setPasswordConfirmation}
          placeholder={"Repeat Password"}
        />
      </div>

      <div className="fixed register-button">
        <CustomButton
          onClick={handleRegister}
          style={{ height: "100%" }}
          disabled={registerDisabled}
        >
          Register
        </CustomButton>
      </div>
    </>
  );
};

export default RegisterPage;
