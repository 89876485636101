import { setNotification } from "../Notifications";
import axios from "axios";
import { deleteCookie, getCookie, setCookie } from "../cookie";

async function handleRequestRegister(userData) {
  // post a request to the user/auth path with the email in the body using axios fetch
  let response = 0;
  let body = {
    name: userData.username,
    email: userData.email,
    password: userData.password,
    password_confirmation: userData.passwordConfirmation,
  };
  await axios
    .post(process.env.REACT_APP_API_URL + "/register", body)
    .then((res) => {
      let data = res.data;
      let jwtToken = data.token;

      // set the jwt token cookie
      setCookie(process.env.REACT_APP_COOKIE_OBFUSCATE, jwtToken, 30);

      // set the user information for later
      localStorage.setItem("user", JSON.stringify(res.data.user));
    })
    .catch((err) => {
      response = err.response.data;
    });

  return response;
}

async function handleRequestLogin(userData) {
  let response = 0;
  let body = {
    name: userData.username,
    password: userData.password,
  };
  await axios
    .post(process.env.REACT_APP_API_URL + "/login", body)
    .then((res) => {
      let data = res.data;
      let jwtToken = data.token;

      // set the jwt token cookie
      setCookie(process.env.REACT_APP_COOKIE_OBFUSCATE, jwtToken, 30);

      localStorage.setItem("user", JSON.stringify(res.data.user));
    })
    .catch((err) => {
      response = err.response.data;
    });

  return response;
}

function LogOut() {
  deleteCookie(process.env.REACT_APP_COOKIE_OBFUSCATE);
  localStorage.removeItem("user");

  return true;
}

// we can determine quickly if the user is logged in or not by checking if a login cookie exists
function isLoggedIn() {
  return getCookie(process.env.REACT_APP_COOKIE_OBFUSCATE) !== null;
}

export { isLoggedIn, LogOut, handleRequestRegister, handleRequestLogin };
